<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL REPORTE:
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-check-double fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listReport.length}}</span>
                                <br>
                                <span class="text-muted">Reportes Registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Reporte</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" @click="modalRegReporte = true">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva Reporte</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>

            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Listado de Reporte Registrados </span>
                        <b-button v-if="listReport.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listReport" :fields="camposReporte" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nombreGuiaPrincipal)="data">
                                        <template v-if="listUsuarios.find(x => x.idGuia == data.item.idGuia) == null">
                                            {{''}}
                                        </template>
                                        <template v-else>
                                            {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}}
                                        </template>
                                        <!-- {{listUsuarios.find(x => x.idGuia == data.item.idGuia).nombre | truncate(50)}} -->
                                    </template>
                                    <template v-slot:cell(fecha)="data">
                                        <!-- {{data.item.fecha}} -->
                                        {{formatearFecha(data.item.fecha,'DD-MM-YYYY')}}
                                    </template>
                                    <template v-slot:cell(comentario)="data">
                                        {{data.item.comentario | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActReporte(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarReporte(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalRegReporte" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Nuevo Reporte</span>
                </h6>
                <CButtonClose @click="modalRegReporte = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(registrarReporte)">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                <b-form-group label="Guia Principal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosRegReporte.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosRegReporte.idGuia" :options="listUsuarios" @search:blur="blurRegReporte">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha de reporte" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de Reporte:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model.trim="datosRegReporte.fecha"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="comentario" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Comentario(Opcional):" class="mb-2">
                                    <b-form-textarea v-model.trim="datosRegReporte.comentario" :state="getValidationState(validationContext)" placeholder="Ingrese el Comentario" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegReporte = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>

        <CModal :show.sync="modalActReporte" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Actualizar Reporte</span>
                </h6>
                <CButtonClose @click="modalActReporte = false" class="text-white" />
            </template>
            <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarReporte)">
                    <b-row>
                        <b-col md="12">
                            <validation-provider name="guia principal" rules="required" v-slot="{errors}">
                                <b-form-group label="Guia Principal:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listUsuarios => listUsuarios.idGuia" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActReporte.idGuia , 'style-invalid-select is-invalid': !!errors.length}" v-model.trim="datosActReporte.idGuia" :options="listUsuarios" @search:blur="blurActReporte">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha de reporte" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de Reporte:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model.trim="datosActReporte.fecha"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="comentario" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Comentario:" class="mb-2">
                                    <b-form-textarea v-model.trim="datosActReporte.comentario" :state="getValidationState(validationContext)" placeholder="Ingrese el Comentario" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Actualizar
                            </b-button>
                            <b-button variant="danger" @click="modalActReporte = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
const dbReporte = firebase.firestore().collection('reporte');
const dbUsuario = firebase.firestore().collection('users');
import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            listReport: [],
            listUsuarios: [],
            disabled: false,

            camposReporte: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombreGuiaPrincipal",
                    label: "Guia Principal",
                    class: "text-center",
                },
                {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "comentario",
                    label: "Comentario",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            datosRegReporte: {
                idGuia: '',
                fecha: moment().format('YYYY-MM-DD'),
                comentario: '',
            },

            datosActReporte: {
                id: '',
                idGuia: '',
                fecha: '',
                comentario: '',
            },

            modalRegReporte: false,
            modalActReporte: false,
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listReport.map(data => {
                return {
                    A: valUpper(this.listUsuarios && this.listUsuarios !== undefined ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia).length > 0 ?
                        this.listUsuarios.filter(x => x.idGuia == data.idGuia)[0].nombre : '' : ''),
                    B: valDate(data['fecha']),
                    C: valUpper(data['comentario']),
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 15, //B
                },
                {
                    wch: 50, //C
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'REPORTE',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NOMBRE DE GUÍA',
                },
                {
                    f: 'B4',
                    v: 'FECHA DEL REPORTE',
                },
                {
                    f: 'C4',
                    v: 'COMENTARIO',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'REPORTE');
            XLSX.writeFile(wb, `REPORTE ${now}.xlsx`);
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        formatearFecha(fecha,formato){
            return moment(fecha).format(formato);
        },
        blurRegReporte() {
            this.computedForms.refs['guia principal'].validate();
        },
        blurActReporte() {
            this.computedFormActualizar.refs['guia principal'].validate();
        },
        listarUsuarios() {
            let me = this;

            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listUsuarios = [];
                querySnapshot.forEach((doc) => {
                    me.listUsuarios.push({
                        idGuia: doc.id,
                        nombre: doc.data().nombre + ' ' + doc.data().apellidoPaterno + ' ' + doc.data().apellidoMaterno,
                    });
                });
            });
        },
        registrarReporte() {
            let me = this;

            me.disabled = true;

            dbReporte.add({
                idGuia: me.datosRegReporte.idGuia.trim(),
                fecha: me.datosRegReporte.fecha.trim(),
                comentario: me.datosRegReporte.comentario.trim(),
                estado: 2,
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaModificacion: '',
                fechaEliminar: '',
                idCliente: me.$store.state.user.idCliente.trim(),
            }).then((doc) => {
                me.swat('success', 'Registrado satisfactoriamente')
                me.modalRegReporte = false;
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        listarReporte() {
            let me = this;

            dbReporte
            .where("estado","==", 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listReport = [];
                querySnapshot.forEach((doc) => {
                    me.listReport.push({
                        id: doc.id,
                        idGuia: doc.data().idGuia,
                        fecha: doc.data().fecha,
                        comentario: doc.data().comentario,
                        fechaCreacion: doc.data().fechaCreacion,
                    });
                });

                me.listReport.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        eliminarReporte(param) {
            let me = this;

            me.$swal.fire({
                title: '¿Estas seguro de eliminar el Reporte?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbReporte.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        me.disabled = false;
                    })
                    .catch((error) => {
                        me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });
                }
            });
        },
        abrirModalActReporte(param) {
            let me = this;

            me.datosActReporte.id = param.item.id;
            me.datosActReporte.idGuia = param.item.idGuia.trim();
            me.datosActReporte.fecha = param.item.fecha;
            me.datosActReporte.comentario = param.item.comentario.trim();

            me.modalActReporte = true;
        },
        actualizarReporte() {
            let me = this;

            me.disabled = true;

            dbReporte.doc(me.datosActReporte.id).update({
                idGuia: me.datosActReporte.idGuia.trim(),
                fecha: me.datosActReporte.fecha.trim(),
                comentario: me.datosActReporte.comentario.trim(),
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }).then((doc)=> {
                me.swat('success', 'Modificado satisfactoriamente');
                me.modalActReporte = false;
                me.disabled = false;
            }).catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegReporte() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });

            this.datosRegReporte.idGuia = '';
            this.datosRegReporte.fecha = moment().format('YYYY-MM-DD');
            this.datosRegReporte.comentario = '';
        },
        resetModalActReporte() {
            this.$nextTick(() => {
                this.$refs.observer1.reset();
            });

            this.datosActReporte.id = '';
            this.datosActReporte.idGuia = '';
            this.datosActReporte.fecha = moment().format('YYYY-MM-DD');
            this.datosActReporte.comentario = '';
        },
    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer1;
        },
    },
    watch: {
        modalRegReporte: function (val) {
            if (val == false) {
                this.resetModalRegReporte();
            }
        },
        modalActReporte: function (val) {
            if (val == false) {
                this.resetModalActReporte();
            }
        },
    },
    mounted(){
        if (this.$store.state.user) {
            this.listarUsuarios();
            this.listarReporte();
        }
    }
}
</script>